//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ListSaryahPlans from '@/modules/plan/components/list-saryah-plans.vue';
import ListMyCustomPlans from '@/modules/plan/components/list-my-custom-plans.vue';

import { mapGetters } from 'vuex';
import { PlanPermissions } from '@/modules/plan/plan-permissions';
// import { i18n } from '@/i18n';

export default {
  name: 'app-plan-list-page',

  components: {
    [ListSaryahPlans.name]: ListSaryahPlans,
    [ListMyCustomPlans.name]: ListMyCustomPlans,
  },
  data() {
    return {
      dialogVisible: false,
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      is_screen_xs: 'layout/is_screen_xs',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    hasPermissionToCreate() {
      return new PlanPermissions(this.currentUser).create;
    },
  },
  methods: {
    i18n(key, args) {
      // return i18n(key, args);
      return this.$t(key, args);
    },
    doOpenModal() {
      this.dialogVisible = true;
    },
    onModalClose() {
      this.dialogVisible = false;
    },
  },
};
