//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import PlanListToolbar from '@/modules/plan/components/plan-list-toolbar.vue';
// import PlanListFilter from '@/modules/plan/components/plan-list-filter.vue';
// import PlanFilterModal from '@/modules/plan/components/plan-filter-modal.vue';
// import PlanListTable from '@/modules/plan/components/plan-list-table.vue';
import PlanCard from '@/modules/plan/components/plan-card.vue';

import { mapGetters, mapActions } from 'vuex';
import { PlanPermissions } from '@/modules/plan/plan-permissions';
import { i18n } from '@/vueI18n';

export default {
  name: 'app-list-saryah-plans',

  components: {
    // [PlanListToolbar.name]: PlanListToolbar,
    // [PlanListFilter.name]: PlanListFilter,
    // [PlanFilterModal.name]: PlanFilterModal,
    // [PlanListTable.name]: PlanListTable,
    [PlanCard.name]: PlanCard,
  },
  data() {
    return {
      dialogVisible: false,
      pagination: {
        page: 1,
        rowsPerPage: 5
      },
      page: 1,
     }
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      is_screen_xs: 'layout/is_screen_xs',
      currentLanguageCode: 'layout/currentLanguageCode',
      saryahPlans: 'plan/list/saryahPlans',
      rowsSaryahPlans: 'plan/list/rowsSaryahPlans',
      loadingSaryahPlans: 'plan/list/loadingSaryahPlans',
      disableNextSaryah: 'plan/list/disableNextSaryah',
      disablePreviousSaryah: 'plan/list/disablePreviousSaryah',

      saryahPlansFirstPage: 'plan/list/saryahPlansFirstPage',
      saryahPlansLastPage: 'plan/list/saryahPlansLastPage'
    }),
    hasPermissionToCreate() {
      return new PlanPermissions(this.currentUser).create;
    },
    isRTL(){
      return i18n.locale == 'ar'
    },
    getItemsPerPage() {
        // const { screen } = this.$q;
        // if (screen.lt.sm) {
        //     return 4;
        // }
        // if (screen.lt.md) {
        //     return 6;
        // }
        // if (screen.lt.lg) {
        //     return 9;
        // }
        // if (screen.lt.xl) {
        //     return 9;
        // }
        // return 9;
        return 10;
    },
  },
  methods: {
    i18n(key, args) {
      // return i18n(key, args);
      return this.$t(key, args);
    },
    doOpenModal() {
      this.dialogVisible = true;
    },
    onModalClose() {
      this.dialogVisible = false;
    },
    ...mapActions({
      doFetchSaryahPlans:  'plan/list/doFetchSaryahPlans',
      doFetch: 'plan/list/doFetch',
      setDisablePreviousSaryah: 'plan/list/setDisablePreviousSaryah',
      setDisableNextSaryah: 'plan/list/setDisableNextSaryah',
    }),
    async goToPreviousPage(){
      console.log('go to previous page');
      const orderBy = 'createdAt'
      const pagination = {
          sortBy: "desc",
          limit: this.getItemsPerPage,
          action: "prev",
          page: this.page
      }
      await this.doFetch({ filter: { type: "default" }, orderBy, pagination})     
      this.page -= 1
    },
    async loadMore(){
      console.log('load more');
      const orderBy = 'createdAt'
      const pagination = {
          sortBy: "desc",
          limit: this.getItemsPerPage,
          action: "next",
          page: this.page
      }
      await this.doFetch({ filter: { type: "default" }, orderBy, pagination})
      this.page += 1
    }
  },
  async mounted(){
    await this.doFetch({
      filter: {
        type: "default"
      },
      orderBy: "createdAt",
      pagination: {
          sortBy: "desc",
          limit: this.getItemsPerPage
      }
    })
  }
};
